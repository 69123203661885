export const AtomsAtomLoader = () => import('../../components/atoms/AtomLoader.vue' /* webpackChunkName: "components/atoms-atom-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsProgressBar = () => import('../../components/atoms/ProgressBar.vue' /* webpackChunkName: "components/atoms-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeAvailableSpacesTriggerOnMobile = () => import('../../components/molecules/MoleculeAvailableSpacesTriggerOnMobile.vue' /* webpackChunkName: "components/molecules-molecule-available-spaces-trigger-on-mobile" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeMenuTrigger = () => import('../../components/molecules/MoleculeMenuTrigger.vue' /* webpackChunkName: "components/molecules-molecule-menu-trigger" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMoleculeUniverseOrientationBar = () => import('../../components/molecules/MoleculeUniverseOrientationBar.vue' /* webpackChunkName: "components/molecules-molecule-universe-orientation-bar" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateBrochure = () => import('../../components/templates/TemplateBrochure.vue' /* webpackChunkName: "components/templates-template-brochure" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateDefaultPage = () => import('../../components/templates/TemplateDefaultPage.vue' /* webpackChunkName: "components/templates-template-default-page" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateLanding = () => import('../../components/templates/TemplateLanding.vue' /* webpackChunkName: "components/templates-template-landing" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateProject = () => import('../../components/templates/TemplateProject.vue' /* webpackChunkName: "components/templates-template-project" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateProjectSpace = () => import('../../components/templates/TemplateProjectSpace.vue' /* webpackChunkName: "components/templates-template-project-space" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateThankYou = () => import('../../components/templates/TemplateThankYou.vue' /* webpackChunkName: "components/templates-template-thank-you" */).then(c => wrapFunctional(c.default || c))
export const TemplatesTemplateThankYouBrochure = () => import('../../components/templates/TemplateThankYouBrochure.vue' /* webpackChunkName: "components/templates-template-thank-you-brochure" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBar = () => import('../../components/organisms/OrganismMenuBar.vue' /* webpackChunkName: "components/organisms-organism-menu-bar" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarEmbedProject = () => import('../../components/organisms/OrganismMenuBarEmbedProject.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-embed-project" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarOffer = () => import('../../components/organisms/OrganismMenuBarOffer.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-offer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismMenuBarProject = () => import('../../components/organisms/OrganismMenuBarProject.vue' /* webpackChunkName: "components/organisms-organism-menu-bar-project" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismModals = () => import('../../components/organisms/OrganismModals.vue' /* webpackChunkName: "components/organisms-organism-modals" */).then(c => wrapFunctional(c.default || c))
export const OrganismsOrganismSidebar = () => import('../../components/organisms/OrganismSidebar.vue' /* webpackChunkName: "components/organisms-organism-sidebar" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomBulletSpecs = () => import('../../components/atoms/common/AtomBulletSpecs.vue' /* webpackChunkName: "components/atoms-common-atom-bullet-specs" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomBulletSpecsNoCategory = () => import('../../components/atoms/common/AtomBulletSpecsNoCategory.vue' /* webpackChunkName: "components/atoms-common-atom-bullet-specs-no-category" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomButton = () => import('../../components/atoms/common/AtomButton.vue' /* webpackChunkName: "components/atoms-common-atom-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomButtonLink = () => import('../../components/atoms/common/AtomButtonLink.vue' /* webpackChunkName: "components/atoms-common-atom-button-link" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCheck = () => import('../../components/atoms/common/AtomCheck.vue' /* webpackChunkName: "components/atoms-common-atom-check" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCircleProgress = () => import('../../components/atoms/common/AtomCircleProgress.vue' /* webpackChunkName: "components/atoms-common-atom-circle-progress" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCircleProgressLoader = () => import('../../components/atoms/common/AtomCircleProgressLoader.vue' /* webpackChunkName: "components/atoms-common-atom-circle-progress-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomContentText = () => import('../../components/atoms/common/AtomContentText.vue' /* webpackChunkName: "components/atoms-common-atom-content-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCustomButton = () => import('../../components/atoms/common/AtomCustomButton.vue' /* webpackChunkName: "components/atoms-common-atom-custom-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomCustomer = () => import('../../components/atoms/common/AtomCustomer.vue' /* webpackChunkName: "components/atoms-common-atom-customer" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomDropdown = () => import('../../components/atoms/common/AtomDropdown.vue' /* webpackChunkName: "components/atoms-common-atom-dropdown" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomFacts = () => import('../../components/atoms/common/AtomFacts.vue' /* webpackChunkName: "components/atoms-common-atom-facts" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomIcon = () => import('../../components/atoms/common/AtomIcon.vue' /* webpackChunkName: "components/atoms-common-atom-icon" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomIconAndText = () => import('../../components/atoms/common/AtomIconAndText.vue' /* webpackChunkName: "components/atoms-common-atom-icon-and-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomImageTitle = () => import('../../components/atoms/common/AtomImageTitle.vue' /* webpackChunkName: "components/atoms-common-atom-image-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomInput = () => import('../../components/atoms/common/AtomInput.vue' /* webpackChunkName: "components/atoms-common-atom-input" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLazyLoader = () => import('../../components/atoms/common/AtomLazyLoader.vue' /* webpackChunkName: "components/atoms-common-atom-lazy-loader" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLinesBullet = () => import('../../components/atoms/common/AtomLinesBullet.vue' /* webpackChunkName: "components/atoms-common-atom-lines-bullet" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoaderLoadingBar = () => import('../../components/atoms/common/AtomLoaderLoadingBar.vue' /* webpackChunkName: "components/atoms-common-atom-loader-loading-bar" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoaderProject = () => import('../../components/atoms/common/AtomLoaderProject.vue' /* webpackChunkName: "components/atoms-common-atom-loader-project" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLoadingError = () => import('../../components/atoms/common/AtomLoadingError.vue' /* webpackChunkName: "components/atoms-common-atom-loading-error" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLocaleSwitch = () => import('../../components/atoms/common/AtomLocaleSwitch.vue' /* webpackChunkName: "components/atoms-common-atom-locale-switch" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLogo = () => import('../../components/atoms/common/AtomLogo.vue' /* webpackChunkName: "components/atoms-common-atom-logo" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomLottieAnimation = () => import('../../components/atoms/common/AtomLottieAnimation.vue' /* webpackChunkName: "components/atoms-common-atom-lottie-animation" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomPerson = () => import('../../components/atoms/common/AtomPerson.vue' /* webpackChunkName: "components/atoms-common-atom-person" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomRadio = () => import('../../components/atoms/common/AtomRadio.vue' /* webpackChunkName: "components/atoms-common-atom-radio" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomRangeSlider = () => import('../../components/atoms/common/AtomRangeSlider.vue' /* webpackChunkName: "components/atoms-common-atom-range-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomScrollUp = () => import('../../components/atoms/common/AtomScrollUp.vue' /* webpackChunkName: "components/atoms-common-atom-scroll-up" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSelect = () => import('../../components/atoms/common/AtomSelect.vue' /* webpackChunkName: "components/atoms-common-atom-select" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSimpleButton = () => import('../../components/atoms/common/AtomSimpleButton.vue' /* webpackChunkName: "components/atoms-common-atom-simple-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSpecsInfo = () => import('../../components/atoms/common/AtomSpecsInfo.vue' /* webpackChunkName: "components/atoms-common-atom-specs-info" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSpecsList = () => import('../../components/atoms/common/AtomSpecsList.vue' /* webpackChunkName: "components/atoms-common-atom-specs-list" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomSquare = () => import('../../components/atoms/common/AtomSquare.vue' /* webpackChunkName: "components/atoms-common-atom-square" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomStatus = () => import('../../components/atoms/common/AtomStatus.vue' /* webpackChunkName: "components/atoms-common-atom-status" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomTenants = () => import('../../components/atoms/common/AtomTenants.vue' /* webpackChunkName: "components/atoms-common-atom-tenants" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomText = () => import('../../components/atoms/common/AtomText.vue' /* webpackChunkName: "components/atoms-common-atom-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomTitle = () => import('../../components/atoms/common/AtomTitle.vue' /* webpackChunkName: "components/atoms-common-atom-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomTitleAndDescription = () => import('../../components/atoms/common/AtomTitleAndDescription.vue' /* webpackChunkName: "components/atoms-common-atom-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomToggle = () => import('../../components/atoms/common/AtomToggle.vue' /* webpackChunkName: "components/atoms-common-atom-toggle" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonAtomVerticalLine = () => import('../../components/atoms/common/AtomVerticalLine.vue' /* webpackChunkName: "components/atoms-common-atom-vertical-line" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomContact = () => import('../../components/atoms/portfolio/AtomContact.vue' /* webpackChunkName: "components/atoms-portfolio-atom-contact" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomCreator = () => import('../../components/atoms/portfolio/AtomCreator.vue' /* webpackChunkName: "components/atoms-portfolio-atom-creator" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomListCircle = () => import('../../components/atoms/portfolio/AtomListCircle.vue' /* webpackChunkName: "components/atoms-portfolio-atom-list-circle" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomPartner = () => import('../../components/atoms/portfolio/AtomPartner.vue' /* webpackChunkName: "components/atoms-portfolio-atom-partner" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectPortfolio = () => import('../../components/atoms/portfolio/AtomProjectPortfolio.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectSliderBackground = () => import('../../components/atoms/portfolio/AtomProjectSliderBackground.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-slider-background" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomProjectSliderThumb = () => import('../../components/atoms/portfolio/AtomProjectSliderThumb.vue' /* webpackChunkName: "components/atoms-portfolio-atom-project-slider-thumb" */).then(c => wrapFunctional(c.default || c))
export const AtomsPortfolioAtomTeam = () => import('../../components/atoms/portfolio/AtomTeam.vue' /* webpackChunkName: "components/atoms-portfolio-atom-team" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomCategorySpecs = () => import('../../components/atoms/project/AtomCategorySpecs.vue' /* webpackChunkName: "components/atoms-project-atom-category-specs" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomLocationType = () => import('../../components/atoms/project/AtomLocationType.vue' /* webpackChunkName: "components/atoms-project-atom-location-type" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomRoute = () => import('../../components/atoms/project/AtomRoute.vue' /* webpackChunkName: "components/atoms-project-atom-route" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectAtomSpaceDropdown = () => import('../../components/atoms/project/AtomSpaceDropdown.vue' /* webpackChunkName: "components/atoms-project-atom-space-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeAnchor = () => import('../../components/molecules/common/MoleculeAnchor.vue' /* webpackChunkName: "components/molecules-common-molecule-anchor" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeAnchorButtons = () => import('../../components/molecules/common/MoleculeAnchorButtons.vue' /* webpackChunkName: "components/molecules-common-molecule-anchor-buttons" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeAnchorV2 = () => import('../../components/molecules/common/MoleculeAnchorV2.vue' /* webpackChunkName: "components/molecules-common-molecule-anchor-v2" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeCertifications = () => import('../../components/molecules/common/MoleculeCertifications.vue' /* webpackChunkName: "components/molecules-common-molecule-certifications" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContactForm = () => import('../../components/molecules/common/MoleculeContactForm.vue' /* webpackChunkName: "components/molecules-common-molecule-contact-form" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentSlider = () => import('../../components/molecules/common/MoleculeContentSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-content-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentSliderBrochure = () => import('../../components/molecules/common/MoleculeContentSliderBrochure.vue' /* webpackChunkName: "components/molecules-common-molecule-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeContentThumbSliderBrochure = () => import('../../components/molecules/common/MoleculeContentThumbSliderBrochure.vue' /* webpackChunkName: "components/molecules-common-molecule-content-thumb-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeCustomers = () => import('../../components/molecules/common/MoleculeCustomers.vue' /* webpackChunkName: "components/molecules-common-molecule-customers" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeExplore = () => import('../../components/molecules/common/MoleculeExplore.vue' /* webpackChunkName: "components/molecules-common-molecule-explore" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeFiltersPins3D = () => import('../../components/molecules/common/MoleculeFiltersPins3D.vue' /* webpackChunkName: "components/molecules-common-molecule-filters-pins3-d" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeGoogleMap = () => import('../../components/molecules/common/MoleculeGoogleMap.vue' /* webpackChunkName: "components/molecules-common-molecule-google-map" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeIconListWithTexts = () => import('../../components/molecules/common/MoleculeIconListWithTexts.vue' /* webpackChunkName: "components/molecules-common-molecule-icon-list-with-texts" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageInfo = () => import('../../components/molecules/common/MoleculeImageInfo.vue' /* webpackChunkName: "components/molecules-common-molecule-image-info" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageSlider = () => import('../../components/molecules/common/MoleculeImageSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-image-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageSliderWithThumbnails = () => import('../../components/molecules/common/MoleculeImageSliderWithThumbnails.vue' /* webpackChunkName: "components/molecules-common-molecule-image-slider-with-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeImageThumbSlider = () => import('../../components/molecules/common/MoleculeImageThumbSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-image-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeListCircle = () => import('../../components/molecules/common/MoleculeListCircle.vue' /* webpackChunkName: "components/molecules-common-molecule-list-circle" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSidebarAccordionResults = () => import('../../components/molecules/common/MoleculeSidebarAccordionResults.vue' /* webpackChunkName: "components/molecules-common-molecule-sidebar-accordion-results" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSliderSlide = () => import('../../components/molecules/common/MoleculeSliderSlide.vue' /* webpackChunkName: "components/molecules-common-molecule-slider-slide" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeSpecsList = () => import('../../components/molecules/common/MoleculeSpecsList.vue' /* webpackChunkName: "components/molecules-common-molecule-specs-list" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeStatementsSlider = () => import('../../components/molecules/common/MoleculeStatementsSlider.vue' /* webpackChunkName: "components/molecules-common-molecule-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeTitleAndDescription = () => import('../../components/molecules/common/MoleculeTitleAndDescription.vue' /* webpackChunkName: "components/molecules-common-molecule-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const MoleculesCommonMoleculeVideoSliderWithThumbnails = () => import('../../components/molecules/common/MoleculeVideoSliderWithThumbnails.vue' /* webpackChunkName: "components/molecules-common-molecule-video-slider-with-thumbnails" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeCreator = () => import('../../components/molecules/portfolio/MoleculeCreator.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-creator" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculePartner = () => import('../../components/molecules/portfolio/MoleculePartner.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-partner" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectPortfolio = () => import('../../components/molecules/portfolio/MoleculeProjectPortfolio.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectSliderBackground = () => import('../../components/molecules/portfolio/MoleculeProjectSliderBackground.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-slider-background" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeProjectSliderThumb = () => import('../../components/molecules/portfolio/MoleculeProjectSliderThumb.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-project-slider-thumb" */).then(c => wrapFunctional(c.default || c))
export const MoleculesPortfolioMoleculeTeam = () => import('../../components/molecules/portfolio/MoleculeTeam.vue' /* webpackChunkName: "components/molecules-portfolio-molecule-team" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeBuildingFloorLabel = () => import('../../components/molecules/project/MoleculeBuildingFloorLabel.vue' /* webpackChunkName: "components/molecules-project-molecule-building-floor-label" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeBuildingLabel = () => import('../../components/molecules/project/MoleculeBuildingLabel.vue' /* webpackChunkName: "components/molecules-project-molecule-building-label" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeBuildingSelectedSpaceDetails = () => import('../../components/molecules/project/MoleculeBuildingSelectedSpaceDetails.vue' /* webpackChunkName: "components/molecules-project-molecule-building-selected-space-details" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeDownloadBrochure = () => import('../../components/molecules/project/MoleculeDownloadBrochure.vue' /* webpackChunkName: "components/molecules-project-molecule-download-brochure" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeFitoutSelector = () => import('../../components/molecules/project/MoleculeFitoutSelector.vue' /* webpackChunkName: "components/molecules-project-molecule-fitout-selector" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePin = () => import('../../components/molecules/project/MoleculePin.vue' /* webpackChunkName: "components/molecules-project-molecule-pin" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculePinExpand = () => import('../../components/molecules/project/MoleculePinExpand.vue' /* webpackChunkName: "components/molecules-project-molecule-pin-expand" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeRequestOffer = () => import('../../components/molecules/project/MoleculeRequestOffer.vue' /* webpackChunkName: "components/molecules-project-molecule-request-offer" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeSendRequest = () => import('../../components/molecules/project/MoleculeSendRequest.vue' /* webpackChunkName: "components/molecules-project-molecule-send-request" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeSidebarRoutes = () => import('../../components/molecules/project/MoleculeSidebarRoutes.vue' /* webpackChunkName: "components/molecules-project-molecule-sidebar-routes" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectMoleculeZoomSlider = () => import('../../components/molecules/project/MoleculeZoomSlider.vue' /* webpackChunkName: "components/molecules-project-molecule-zoom-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsBaseSectionBrochure = () => import('../../components/sections/base/SectionBrochure.vue' /* webpackChunkName: "components/sections-base-section-brochure" */).then(c => wrapFunctional(c.default || c))
export const SectionsBaseSectionCustomModel = () => import('../../components/sections/base/SectionCustomModel.vue' /* webpackChunkName: "components/sections-base-section-custom-model" */).then(c => wrapFunctional(c.default || c))
export const SectionsBaseSectionSelector = () => import('../../components/sections/base/SectionSelector.vue' /* webpackChunkName: "components/sections-base-section-selector" */).then(c => wrapFunctional(c.default || c))
export const SectionsBaseSectionSlider = () => import('../../components/sections/base/SectionSlider.vue' /* webpackChunkName: "components/sections-base-section-slider" */).then(c => wrapFunctional(c.default || c))
export const SectionsBaseSectionThankYou = () => import('../../components/sections/base/SectionThankYou.vue' /* webpackChunkName: "components/sections-base-section-thank-you" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionBackgroundImage = () => import('../../components/sections/general/SectionBackgroundImage.vue' /* webpackChunkName: "components/sections-general-section-background-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionDiscoverArea = () => import('../../components/sections/general/SectionDiscoverArea.vue' /* webpackChunkName: "components/sections-general-section-discover-area" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionExploreBuilding = () => import('../../components/sections/general/SectionExploreBuilding.vue' /* webpackChunkName: "components/sections-general-section-explore-building" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionFewFacts = () => import('../../components/sections/general/SectionFewFacts.vue' /* webpackChunkName: "components/sections-general-section-few-facts" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionIconsDescriptions = () => import('../../components/sections/general/SectionIconsDescriptions.vue' /* webpackChunkName: "components/sections-general-section-icons-descriptions" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionImageInfo = () => import('../../components/sections/general/SectionImageInfo.vue' /* webpackChunkName: "components/sections-general-section-image-info" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionImageSide = () => import('../../components/sections/general/SectionImageSide.vue' /* webpackChunkName: "components/sections-general-section-image-side" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionInfoAndImage = () => import('../../components/sections/general/SectionInfoAndImage.vue' /* webpackChunkName: "components/sections-general-section-info-and-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionPostAndImage = () => import('../../components/sections/general/SectionPostAndImage.vue' /* webpackChunkName: "components/sections-general-section-post-and-image" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionPurposeAndValues = () => import('../../components/sections/general/SectionPurposeAndValues.vue' /* webpackChunkName: "components/sections-general-section-purpose-and-values" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionStatements = () => import('../../components/sections/general/SectionStatements.vue' /* webpackChunkName: "components/sections-general-section-statements" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionTenants = () => import('../../components/sections/general/SectionTenants.vue' /* webpackChunkName: "components/sections-general-section-tenants" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionVideo = () => import('../../components/sections/general/SectionVideo.vue' /* webpackChunkName: "components/sections-general-section-video" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSectionWhyUs = () => import('../../components/sections/general/SectionWhyUs.vue' /* webpackChunkName: "components/sections-general-section-why-us" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionBrochureButton = () => import('../../components/sections/project/SectionBrochureButton.vue' /* webpackChunkName: "components/sections-project-section-brochure-button" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionBuilding = () => import('../../components/sections/project/SectionBuilding.vue' /* webpackChunkName: "components/sections-project-section-building" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionContact = () => import('../../components/sections/project/SectionContact.vue' /* webpackChunkName: "components/sections-project-section-contact" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionFeatures = () => import('../../components/sections/project/SectionFeatures.vue' /* webpackChunkName: "components/sections-project-section-features" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionGallery = () => import('../../components/sections/project/SectionGallery.vue' /* webpackChunkName: "components/sections-project-section-gallery" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionImageList = () => import('../../components/sections/project/SectionImageList.vue' /* webpackChunkName: "components/sections-project-section-image-list" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionImageOrVideo = () => import('../../components/sections/project/SectionImageOrVideo.vue' /* webpackChunkName: "components/sections-project-section-image-or-video" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionIntro = () => import('../../components/sections/project/SectionIntro.vue' /* webpackChunkName: "components/sections-project-section-intro" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionLocation = () => import('../../components/sections/project/SectionLocation.vue' /* webpackChunkName: "components/sections-project-section-location" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionParking = () => import('../../components/sections/project/SectionParking.vue' /* webpackChunkName: "components/sections-project-section-parking" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSelector = () => import('../../components/sections/project/SectionSelector.vue' /* webpackChunkName: "components/sections-project-section-selector" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSpecs = () => import('../../components/sections/project/SectionSpecs.vue' /* webpackChunkName: "components/sections-project-section-specs" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSectionSustainability = () => import('../../components/sections/project/SectionSustainability.vue' /* webpackChunkName: "components/sections-project-section-sustainability" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionCreators = () => import('../../components/sections/landing/SectionCreators.vue' /* webpackChunkName: "components/sections-landing-section-creators" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionHero = () => import('../../components/sections/landing/SectionHero.vue' /* webpackChunkName: "components/sections-landing-section-hero" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionPartners = () => import('../../components/sections/landing/SectionPartners.vue' /* webpackChunkName: "components/sections-landing-section-partners" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionPortfolioMap = () => import('../../components/sections/landing/SectionPortfolioMap.vue' /* webpackChunkName: "components/sections-landing-section-portfolio-map" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionSelector = () => import('../../components/sections/landing/SectionSelector.vue' /* webpackChunkName: "components/sections-landing-section-selector" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionTeam = () => import('../../components/sections/landing/SectionTeam.vue' /* webpackChunkName: "components/sections-landing-section-team" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionVision = () => import('../../components/sections/landing/SectionVision.vue' /* webpackChunkName: "components/sections-landing-section-vision" */).then(c => wrapFunctional(c.default || c))
export const SectionsLandingSectionWhy = () => import('../../components/sections/landing/SectionWhy.vue' /* webpackChunkName: "components/sections-landing-section-why" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismCreator = () => import('../../components/organisms/portfolio/OrganismCreator.vue' /* webpackChunkName: "components/organisms-portfolio-organism-creator" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismPartner = () => import('../../components/organisms/portfolio/OrganismPartner.vue' /* webpackChunkName: "components/organisms-portfolio-organism-partner" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismProjectPortfolio = () => import('../../components/organisms/portfolio/OrganismProjectPortfolio.vue' /* webpackChunkName: "components/organisms-portfolio-organism-project-portfolio" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismProjectSlider = () => import('../../components/organisms/portfolio/OrganismProjectSlider.vue' /* webpackChunkName: "components/organisms-portfolio-organism-project-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsPortfolioOrganismTeam = () => import('../../components/organisms/portfolio/OrganismTeam.vue' /* webpackChunkName: "components/organisms-portfolio-organism-team" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismAnchors = () => import('../../components/organisms/common/OrganismAnchors.vue' /* webpackChunkName: "components/organisms-common-organism-anchors" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismAnchorsV2 = () => import('../../components/organisms/common/OrganismAnchorsV2.vue' /* webpackChunkName: "components/organisms-common-organism-anchors-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismCertifications = () => import('../../components/organisms/common/OrganismCertifications.vue' /* webpackChunkName: "components/organisms-common-organism-certifications" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismCertificationsV2 = () => import('../../components/organisms/common/OrganismCertificationsV2.vue' /* webpackChunkName: "components/organisms-common-organism-certifications-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismContentSlider = () => import('../../components/organisms/common/OrganismContentSlider.vue' /* webpackChunkName: "components/organisms-common-organism-content-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismContentSliderBrochure = () => import('../../components/organisms/common/OrganismContentSliderBrochure.vue' /* webpackChunkName: "components/organisms-common-organism-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismDrawer = () => import('../../components/organisms/common/OrganismDrawer.vue' /* webpackChunkName: "components/organisms-common-organism-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismExplore = () => import('../../components/organisms/common/OrganismExplore.vue' /* webpackChunkName: "components/organisms-common-organism-explore" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismImageInfo = () => import('../../components/organisms/common/OrganismImageInfo.vue' /* webpackChunkName: "components/organisms-common-organism-image-info" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismImageSlider = () => import('../../components/organisms/common/OrganismImageSlider.vue' /* webpackChunkName: "components/organisms-common-organism-image-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismImageSliderV2 = () => import('../../components/organisms/common/OrganismImageSliderV2.vue' /* webpackChunkName: "components/organisms-common-organism-image-slider-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismListCircle = () => import('../../components/organisms/common/OrganismListCircle.vue' /* webpackChunkName: "components/organisms-common-organism-list-circle" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismPageLoader = () => import('../../components/organisms/common/OrganismPageLoader.vue' /* webpackChunkName: "components/organisms-common-organism-page-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismParkingAccess = () => import('../../components/organisms/common/OrganismParkingAccess.vue' /* webpackChunkName: "components/organisms-common-organism-parking-access" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismParkingAccessV2 = () => import('../../components/organisms/common/OrganismParkingAccessV2.vue' /* webpackChunkName: "components/organisms-common-organism-parking-access-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismParkingAccessV3 = () => import('../../components/organisms/common/OrganismParkingAccessV3.vue' /* webpackChunkName: "components/organisms-common-organism-parking-access-v3" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismSidebarAccordion = () => import('../../components/organisms/common/OrganismSidebarAccordion.vue' /* webpackChunkName: "components/organisms-common-organism-sidebar-accordion" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismSlider = () => import('../../components/organisms/common/OrganismSlider.vue' /* webpackChunkName: "components/organisms-common-organism-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismStatementsSlider = () => import('../../components/organisms/common/OrganismStatementsSlider.vue' /* webpackChunkName: "components/organisms-common-organism-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismTitleAndDescription = () => import('../../components/organisms/common/OrganismTitleAndDescription.vue' /* webpackChunkName: "components/organisms-common-organism-title-and-description" */).then(c => wrapFunctional(c.default || c))
export const OrganismsCommonOrganismTitleTextImage = () => import('../../components/organisms/common/OrganismTitleTextImage.vue' /* webpackChunkName: "components/organisms-common-organism-title-text-image" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismBuildingErrorOccurred = () => import('../../components/organisms/project/OrganismBuildingErrorOccurred.vue' /* webpackChunkName: "components/organisms-project-organism-building-error-occurred" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismBuildingLoader = () => import('../../components/organisms/project/OrganismBuildingLoader.vue' /* webpackChunkName: "components/organisms-project-organism-building-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismContactForm = () => import('../../components/organisms/project/OrganismContactForm.vue' /* webpackChunkName: "components/organisms-project-organism-contact-form" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismDownloadBrochureModal = () => import('../../components/organisms/project/OrganismDownloadBrochureModal.vue' /* webpackChunkName: "components/organisms-project-organism-download-brochure-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismLoader = () => import('../../components/organisms/project/OrganismLoader.vue' /* webpackChunkName: "components/organisms-project-organism-loader" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismLocation = () => import('../../components/organisms/project/OrganismLocation.vue' /* webpackChunkName: "components/organisms-project-organism-location" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismPins = () => import('../../components/organisms/project/OrganismPins.vue' /* webpackChunkName: "components/organisms-project-organism-pins" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismRequestOfferModal = () => import('../../components/organisms/project/OrganismRequestOfferModal.vue' /* webpackChunkName: "components/organisms-project-organism-request-offer-modal" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismTechnicalSpecs = () => import('../../components/organisms/project/OrganismTechnicalSpecs.vue' /* webpackChunkName: "components/organisms-project-organism-technical-specs" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectOrganismTechnicalSpecsV2 = () => import('../../components/organisms/project/OrganismTechnicalSpecsV2.vue' /* webpackChunkName: "components/organisms-project-organism-technical-specs-v2" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSlider2AtomArrowButtonSlider = () => import('../../components/atoms/common/Slider2/AtomArrowButtonSlider.vue' /* webpackChunkName: "components/atoms-common-slider2-atom-arrow-button-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentSlider = () => import('../../components/atoms/common/slider/AtomContentSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentSliderBrochure = () => import('../../components/atoms/common/slider/AtomContentSliderBrochure.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomContentThumbSliderBrochure = () => import('../../components/atoms/common/slider/AtomContentThumbSliderBrochure.vue' /* webpackChunkName: "components/atoms-common-slider-atom-content-thumb-slider-brochure" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomImageSlider = () => import('../../components/atoms/common/slider/AtomImageSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-image-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomImageSliderMatterportSpace = () => import('../../components/atoms/common/slider/AtomImageSliderMatterportSpace.vue' /* webpackChunkName: "components/atoms-common-slider-atom-image-slider-matterport-space" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomImageThumbSlider = () => import('../../components/atoms/common/slider/AtomImageThumbSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-image-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomStatementsSlider = () => import('../../components/atoms/common/slider/AtomStatementsSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-statements-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomVideoSlider = () => import('../../components/atoms/common/slider/AtomVideoSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-video-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomVideoSliderMatterportSpace = () => import('../../components/atoms/common/slider/AtomVideoSliderMatterportSpace.vue' /* webpackChunkName: "components/atoms-common-slider-atom-video-slider-matterport-space" */).then(c => wrapFunctional(c.default || c))
export const AtomsCommonSliderAtomVideoThumbSlider = () => import('../../components/atoms/common/slider/AtomVideoThumbSlider.vue' /* webpackChunkName: "components/atoms-common-slider-atom-video-thumb-slider" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinFitoutButtons = () => import('../../components/atoms/project/building/AtomPinFitoutButtons.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-fitout-buttons" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinIconText = () => import('../../components/atoms/project/building/AtomPinIconText.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-icon-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinImage = () => import('../../components/atoms/project/building/AtomPinImage.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-image" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinInfoContent = () => import('../../components/atoms/project/building/AtomPinInfoContent.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-info-content" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinSimpleText = () => import('../../components/atoms/project/building/AtomPinSimpleText.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-simple-text" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomPinVideo = () => import('../../components/atoms/project/building/AtomPinVideo.vue' /* webpackChunkName: "components/atoms-project-building-atom-pin-video" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomRequestOfferButton = () => import('../../components/atoms/project/building/AtomRequestOfferButton.vue' /* webpackChunkName: "components/atoms-project-building-atom-request-offer-button" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomSidebarInfo = () => import('../../components/atoms/project/building/AtomSidebarInfo.vue' /* webpackChunkName: "components/atoms-project-building-atom-sidebar-info" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomSidebarTitle = () => import('../../components/atoms/project/building/AtomSidebarTitle.vue' /* webpackChunkName: "components/atoms-project-building-atom-sidebar-title" */).then(c => wrapFunctional(c.default || c))
export const AtomsProjectBuildingAtomTrashButton = () => import('../../components/atoms/project/building/AtomTrashButton.vue' /* webpackChunkName: "components/atoms-project-building-atom-trash-button" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityBuildingFilterResultsDisplay = () => import('../../components/molecules/project/building/MoleculeAvailabilityBuildingFilterResultsDisplay.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-building-filter-results-display" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityFilterResultsContainer = () => import('../../components/molecules/project/building/MoleculeAvailabilityFilterResultsContainer.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-filter-results-container" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityFilterResultsList = () => import('../../components/molecules/project/building/MoleculeAvailabilityFilterResultsList.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-filter-results-list" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityFilterStatistics = () => import('../../components/molecules/project/building/MoleculeAvailabilityFilterStatistics.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-filter-statistics" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityFloorFilterResult = () => import('../../components/molecules/project/building/MoleculeAvailabilityFloorFilterResult.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-floor-filter-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilityFloorFilterResultsDisplay = () => import('../../components/molecules/project/building/MoleculeAvailabilityFloorFilterResultsDisplay.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-floor-filter-results-display" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilitySpaceFilterResult = () => import('../../components/molecules/project/building/MoleculeAvailabilitySpaceFilterResult.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-space-filter-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilitySpaceFilterResultBuilding = () => import('../../components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultBuilding.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-space-filter-result-building" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilitySpaceFilterResultCombinedSpace = () => import('../../components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultCombinedSpace.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-space-filter-result-combined-space" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeAvailabilitySpaceFilterResultHeader = () => import('../../components/molecules/project/building/MoleculeAvailabilitySpaceFilterResultHeader.vue' /* webpackChunkName: "components/molecules-project-building-molecule-availability-space-filter-result-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeCartSpaces = () => import('../../components/molecules/project/building/MoleculeCartSpaces.vue' /* webpackChunkName: "components/molecules-project-building-molecule-cart-spaces" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeChooseViewBuildings = () => import('../../components/molecules/project/building/MoleculeChooseViewBuildings.vue' /* webpackChunkName: "components/molecules-project-building-molecule-choose-view-buildings" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeExtraSidebarComponents = () => import('../../components/molecules/project/building/MoleculeExtraSidebarComponents.vue' /* webpackChunkName: "components/molecules-project-building-molecule-extra-sidebar-components" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeExtraSidebarComponentsMobile = () => import('../../components/molecules/project/building/MoleculeExtraSidebarComponentsMobile.vue' /* webpackChunkName: "components/molecules-project-building-molecule-extra-sidebar-components-mobile" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeGuidedTourTimeline = () => import('../../components/molecules/project/building/MoleculeGuidedTourTimeline.vue' /* webpackChunkName: "components/molecules-project-building-molecule-guided-tour-timeline" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeImageSlider3DTour = () => import('../../components/molecules/project/building/MoleculeImageSlider3DTour.vue' /* webpackChunkName: "components/molecules-project-building-molecule-image-slider3-d-tour" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculePinInfoContent = () => import('../../components/molecules/project/building/MoleculePinInfoContent.vue' /* webpackChunkName: "components/molecules-project-building-molecule-pin-info-content" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeProjectFilters = () => import('../../components/molecules/project/building/MoleculeProjectFilters.vue' /* webpackChunkName: "components/molecules-project-building-molecule-project-filters" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeProjectFiltersDropdown = () => import('../../components/molecules/project/building/MoleculeProjectFiltersDropdown.vue' /* webpackChunkName: "components/molecules-project-building-molecule-project-filters-dropdown" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeProjectFiltersHeader = () => import('../../components/molecules/project/building/MoleculeProjectFiltersHeader.vue' /* webpackChunkName: "components/molecules-project-building-molecule-project-filters-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarHeader = () => import('../../components/molecules/project/building/MoleculeSidebarHeader.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-header" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarResult = () => import('../../components/molecules/project/building/MoleculeSidebarResult.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarResultSpaceDetails = () => import('../../components/molecules/project/building/MoleculeSidebarResultSpaceDetails.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-result-space-details" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSidebarResults = () => import('../../components/molecules/project/building/MoleculeSidebarResults.vue' /* webpackChunkName: "components/molecules-project-building-molecule-sidebar-results" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSpaceDetailsOnSpacePage = () => import('../../components/molecules/project/building/MoleculeSpaceDetailsOnSpacePage.vue' /* webpackChunkName: "components/molecules-project-building-molecule-space-details-on-space-page" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSpaceDetailsTotalResult = () => import('../../components/molecules/project/building/MoleculeSpaceDetailsTotalResult.vue' /* webpackChunkName: "components/molecules-project-building-molecule-space-details-total-result" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeSpaceDetailsTotalResultCart = () => import('../../components/molecules/project/building/MoleculeSpaceDetailsTotalResultCart.vue' /* webpackChunkName: "components/molecules-project-building-molecule-space-details-total-result-cart" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingMoleculeVideoSlider3DTour = () => import('../../components/molecules/project/building/MoleculeVideoSlider3DTour.vue' /* webpackChunkName: "components/molecules-project-building-molecule-video-slider3-d-tour" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralContactSectionContactV1 = () => import('../../components/sections/general/contact/SectionContactV1.vue' /* webpackChunkName: "components/sections-general-contact-section-contact-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralContactSectionContactV2 = () => import('../../components/sections/general/contact/SectionContactV2.vue' /* webpackChunkName: "components/sections-general-contact-section-contact-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSustainabilitySectionSustainabilityV1 = () => import('../../components/sections/general/sustainability/SectionSustainabilityV1.vue' /* webpackChunkName: "components/sections-general-sustainability-section-sustainability-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSustainabilitySectionSustainabilityV2 = () => import('../../components/sections/general/sustainability/SectionSustainabilityV2.vue' /* webpackChunkName: "components/sections-general-sustainability-section-sustainability-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsGeneralSustainabilitySectionSustainabilityV3 = () => import('../../components/sections/general/sustainability/SectionSustainabilityV3.vue' /* webpackChunkName: "components/sections-general-sustainability-section-sustainability-v3" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectIntroSectionIntroV1 = () => import('../../components/sections/project/intro/SectionIntroV1.vue' /* webpackChunkName: "components/sections-project-intro-section-intro-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectIntroSectionIntroV2 = () => import('../../components/sections/project/intro/SectionIntroV2.vue' /* webpackChunkName: "components/sections-project-intro-section-intro-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectGallerySectionGalleryV1 = () => import('../../components/sections/project/gallery/SectionGalleryV1.vue' /* webpackChunkName: "components/sections-project-gallery-section-gallery-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectGallerySectionGalleryV2 = () => import('../../components/sections/project/gallery/SectionGalleryV2.vue' /* webpackChunkName: "components/sections-project-gallery-section-gallery-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectLocationSectionLocationV1 = () => import('../../components/sections/project/location/SectionLocationV1.vue' /* webpackChunkName: "components/sections-project-location-section-location-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectLocationSectionLocationV2 = () => import('../../components/sections/project/location/SectionLocationV2.vue' /* webpackChunkName: "components/sections-project-location-section-location-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectParkingSectionParkingV1 = () => import('../../components/sections/project/parking/SectionParkingV1.vue' /* webpackChunkName: "components/sections-project-parking-section-parking-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectParkingSectionParkingV2 = () => import('../../components/sections/project/parking/SectionParkingV2.vue' /* webpackChunkName: "components/sections-project-parking-section-parking-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectParkingSectionParkingV3 = () => import('../../components/sections/project/parking/SectionParkingV3.vue' /* webpackChunkName: "components/sections-project-parking-section-parking-v3" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSpecsSectionSpecsV1 = () => import('../../components/sections/project/specs/SectionSpecsV1.vue' /* webpackChunkName: "components/sections-project-specs-section-specs-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSpecsSectionSpecsV2 = () => import('../../components/sections/project/specs/SectionSpecsV2.vue' /* webpackChunkName: "components/sections-project-specs-section-specs-v2" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSustainabilitySectionSustainabilityV1 = () => import('../../components/sections/project/sustainability/SectionSustainabilityV1.vue' /* webpackChunkName: "components/sections-project-sustainability-section-sustainability-v1" */).then(c => wrapFunctional(c.default || c))
export const SectionsProjectSustainabilitySectionSustainabilityV2 = () => import('../../components/sections/project/sustainability/SectionSustainabilityV2.vue' /* webpackChunkName: "components/sections-project-sustainability-section-sustainability-v2" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganism3DContainer = () => import('../../components/organisms/project/building/Organism3DContainer.vue' /* webpackChunkName: "components/organisms-project-building-organism3-d-container" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganism3DModelInteraction = () => import('../../components/organisms/project/building/Organism3DModelInteraction.vue' /* webpackChunkName: "components/organisms-project-building-organism3-d-model-interaction" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismBuilding = () => import('../../components/organisms/project/building/OrganismBuilding.vue' /* webpackChunkName: "components/organisms-project-building-organism-building" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismBuildingLabels = () => import('../../components/organisms/project/building/OrganismBuildingLabels.vue' /* webpackChunkName: "components/organisms-project-building-organism-building-labels" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismCartSpacesBuilding = () => import('../../components/organisms/project/building/OrganismCartSpacesBuilding.vue' /* webpackChunkName: "components/organisms-project-building-organism-cart-spaces-building" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourLauncher = () => import('../../components/organisms/project/building/OrganismGuidedTourLauncher.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-launcher" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourPresentationDrawer = () => import('../../components/organisms/project/building/OrganismGuidedTourPresentationDrawer.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-presentation-drawer" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismGuidedTourTimeline = () => import('../../components/organisms/project/building/OrganismGuidedTourTimeline.vue' /* webpackChunkName: "components/organisms-project-building-organism-guided-tour-timeline" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismPinInfoBox = () => import('../../components/organisms/project/building/OrganismPinInfoBox.vue' /* webpackChunkName: "components/organisms-project-building-organism-pin-info-box" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismProjectAvailabilityOptions = () => import('../../components/organisms/project/building/OrganismProjectAvailabilityOptions.vue' /* webpackChunkName: "components/organisms-project-building-organism-project-availability-options" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismProjectUI = () => import('../../components/organisms/project/building/OrganismProjectUI.vue' /* webpackChunkName: "components/organisms-project-building-organism-project-u-i" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismSelectedSpaceMobile = () => import('../../components/organisms/project/building/OrganismSelectedSpaceMobile.vue' /* webpackChunkName: "components/organisms-project-building-organism-selected-space-mobile" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismSpace = () => import('../../components/organisms/project/building/OrganismSpace.vue' /* webpackChunkName: "components/organisms-project-building-organism-space" */).then(c => wrapFunctional(c.default || c))
export const OrganismsProjectBuildingOrganismTabsTechnicalSpecsV2 = () => import('../../components/organisms/project/building/OrganismTabsTechnicalSpecsV2.vue' /* webpackChunkName: "components/organisms-project-building-organism-tabs-technical-specs-v2" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourMoleculeContentBlocks = () => import('../../components/molecules/project/building/guidedTour/MoleculeContentBlocks.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-molecule-content-blocks" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockEmbed = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockEmbed.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-embed" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockIframe = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockIframe.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-iframe" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockImage = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockImage.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-image" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockText = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockText.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-text" */).then(c => wrapFunctional(c.default || c))
export const MoleculesProjectBuildingGuidedTourContentBlocksMoleculeContentBlockVideo = () => import('../../components/molecules/project/building/guidedTour/contentBlocks/MoleculeContentBlockVideo.vue' /* webpackChunkName: "components/molecules-project-building-guided-tour-content-blocks-molecule-content-block-video" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
